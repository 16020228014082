/* Add this CSS to your component or a separate CSS file */
.form-container {
    display: flex;
  }
  
  .form-group {
    margin: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .form-group input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: fit-content;
  }

  .button {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 5Px 10px;
    margin: 2px;
    cursor: pointer;
  }

  .button.disabled-button {
    opacity: 0.6; /* Reduce opacity to indicate it's disabled */
    cursor: not-allowed; /* Change cursor style to 'not-allowed' */
    pointer-events: none; /* Prevent pointer events on the button */
  }

  .button.green {
    background-color: #28A745;
  }
  
  .button.red {
    background-color: #FF0000;
  }
  
  .table-container {
    max-width: 100%;
    overflow-x: auto;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ccc;
  }
  
  .table th, .table td {
    padding: 5px;
    text-align: left;
  }
  
  .table th {
    background-color: #007BFF;
    color: white;
  }
  
  .table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .table tr:hover {
    background-color: #ddd;
  }
