/* Define A4 page dimensions (approximately 210mm x 297mm) */
@page {
    size: A4;
    margin: 10mm; /* You can adjust the margins as needed */
  }
  
  /* Center the content on the page */
  .ref-body {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  
  /* Style the container for your content */
  .ref-form-container {
    max-width: 100%; /* Ensure the content doesn't exceed the A4 page width */
  }
  
  /* Style the form groups and labels */
  .ref-form-group {
    margin-bottom: 10px;
  }
  
  .ref-label {
    display: block;
    font-weight: bold;
  }
  
  /* Style the input fields */
  .ref-input-text {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 10px;
  }
  
  /* Style the table container */
  .ref-table-container {
    margin-top: 20px;
  }
  
  /* Style the table */
  .ref-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #000;
  }
  
  
  /* Style table headers and cells */
  .ref-t {
    border: 1px solid #000;
    padding: 5px;
    text-align: center;
  }
  
  /* Add a page break before this element */
  .page-break {
    page-break-before: always;
  }
  