
  .button {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 5Px 10px;
    margin: 2px;
    cursor: pointer;
  }

  .button.disabled-button {
    opacity: 0.6; /* Reduce opacity to indicate it's disabled */
    cursor: not-allowed; /* Change cursor style to 'not-allowed' */
    pointer-events: none; /* Prevent pointer events on the button */
  }

  .button.green {
    background-color: #28A745;
  }
  
  .button.red {
    background-color: #FF0000;
  }
  
  .table-container-1 {
    overflow-x: auto;
    max-width: 100%; /* Ensure the container does not extend beyond the window width */
  }
  
  .table-1 {
    width: 100%;
    table-layout: auto;
  }
  
  .table-1.td, .table-1.th {
    white-space: nowrap;
  }
  
  input {
    width: 100%;
    max-width: none; /* Reset any external max-width styles */
    box-sizing: border-box;
  }
  
