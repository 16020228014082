/* CoachCardTable.css */

.coach-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ccc;
  }
  
  .coach-table th, .coach-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ccc;
  }
  
  .coach-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .coach-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .coach-table tr:hover {
    background-color: #ddd;
  }
  
  button {
    padding: 5px 10px;
    cursor: pointer;
    border: none;
    outline: none;
    margin-right: 5px;
  }
  
  button.delete-button {
    background-color: #e74c3c;
    color: white;
  }
  
  button.delete-button:hover {
    background-color: #c0392b;
  }
  
  button.edit-button {
    background-color: #3498db;
    color: white;
  }

  button.edit-button:disabled {
    background-color: #cccccc; /* Change to the desired color for disabled state */
    color: #666666; /* Change to the desired text color for disabled state */
    cursor: not-allowed; /* Optional: Change cursor to not-allowed for disabled state */
}
  
  button.edit-button:hover {
    background-color: #2980b9;
  }

  